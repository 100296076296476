import React, { useEffect, useRef, useState } from "react";
import { Col, Overlay, Nav, Popover, Row } from "react-bootstrap";
import { 
    CalendarStyled,
    HeaderCardStyled,
    InlineStyled,
    NavLinkStyled, 
    NavStyled, 
    NavItemStyled,
    SpanStyled, 
    TextCardStyled
} from './styles'
import moment from 'moment';
import 'moment/locale/pt-br'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CalendarSvg from '../../../assets/icons/calendar.svg'
import ChevronLeft from '../../../assets/icons/chevron-left.svg'
import ChevronRight from '../../../assets/icons/chevron-right.svg'

import Select from "../../Forms/select";
import api from "../../../utils/api";
import ProfessionalCalendarCard from "../ProfessionalCalendarCard";

import ptBR from 'date-fns/locale/pt-BR';

const Calendar = ({professionalId, professionals, publicScheduleId, clinicId}) => {
    const target = useRef(null);
    const [size] = useState(window.screen.width >= 991 ? 'md' : 'sm');
    const [hasAppointments, setHasAppointments] = useState();
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [healthPlanOptions, setHealthPlanOptions] = useState();
    const [scheduleDays, setScheduleDays] = useState(new Array(6));
    const [calendarProfessionals, setCalendarProfessionals] = useState();
    const [selectedProfessional, setSelectedProfessional] = useState();
    const [subspecialty, setSubspecialty] = useState();
    const [appointmentType, setAppointmentType] = useState();
    const [healthPlan, setHealthPlan] = useState();
    const [professionalsLoaded, setProfessionalsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [desactivadDays, setDesactivadDays] = useState([]);
    const [allOutages, setAllOutages] = useState();
    const [clinicOutageDates, setClinicOutageDates] = useState();
    const [professionalOutageDates, setProfessionalOutageDates] = useState();

    useEffect(() => {
        if(professionalId) setSelectedProfessional(professionalId)
    }, [professionalId]);

    useEffect(() => {
        if(selectedProfessional || subspecialty || (healthPlan && appointmentType) || selectedDate) {
            getCalendarProfessionals()
        }
        
        
    }, [selectedProfessional, subspecialty, appointmentType, healthPlan, selectedDate]);

    useEffect(() => {
        if(clinicId){
            getScheduleConfigDay()
            getOutages()
            getHealthPlanOptions()
            setAppointmentType(appoitmentTypesOptions().filter(el => el.label.toLowerCase() === 'consulta')[0].value);
        }
    }, [clinicId]);

    useEffect(() => {
        if(selectedProfessional){
            getProfessionalOutages()
            getHealthPlanOptions()
        }
        else setProfessionalOutageDates([]) 
    }, [selectedProfessional]);

    useEffect(() => {
        if(calendarProfessionals && appointmentType && healthPlan && selectedDate) loadProfessional()
    }, [calendarProfessionals]);
    
    useEffect(() => {
        const desactiveDays = []
        scheduleDays.forEach((day,index) => {
            if(!day.active) desactiveDays.push(index)
        });
        setDesactivadDays(desactiveDays)
    }, [scheduleDays]);

    const openCalendar = () => {
        setOpenDatePicker(!openDatePicker)
    }

    function selectDate(value){
        setDate(moment(value).format('YYYY-MM-DD'))
        setSelectedDate(moment(value).format('YYYY-MM-DD'))
        setOpenDatePicker(false)
    }

    function handleSelect(event){
        setSelectedDate(moment(event).format('YYYY-MM-DD'))
    }

    const professionalsOptions = () => {
        let res = [];
        res = professionals?.filter(professional => {
            return professional.public_schedule_professional?.active
        }).map(professional => {
                return {
                    'label': professional.name,
                    'value': professional.id,
                }
        })
        return res;
    }

    const subSpecialtyOptions = () => {
        if(calendarProfessionals?.length === 1 && selectedProfessional){
            if(calendarProfessionals[0]?.rqe?.specialtie){
                return [
                    { 
                        'value': calendarProfessionals[0]?.rqe?.specialtie,
                        'label': calendarProfessionals[0]?.rqe?.specialtie
                    }
                ]                    
            }
            return []
        }

        return [
            { 
                'value': 'Especialista em Retina',
                'label': 'Especialista em Retina'
            },
            { 
                'value': 'Especialista em Uveites',
                'label': 'Especialista em Uveites'
            },
            { 
                'value': 'Oftalmopediatria',
                'label': 'Oftalmopediatria'
            },
            { 
                'value': 'Especialista em Cirurgia Refrativa',
                'label': 'Especialista em Cirurgia Refrativa'
            },
            { 
                'value': 'Especialista em Estrabagismo',
                'label': 'Especialista em Estrabagismo'
            },
            { 
                'value': 'Especialista em Glaucoma',
                'label': 'Especialista em Glaucoma'
            },
            { 
                'value': 'Especialista em Refração',
                'label': 'Especialista em Refração'
            },
            { 
                'value': 'Especialista em Visão Subnormal',
                'label': 'Especialista em Visão Subnormal'
            },
            { 
                'value': 'Especialista em Catarata',
                'label': 'Especialista em Catarata'
            },
            { 
                'value': 'Plástica Ocular',
                'label': 'Plástica Ocular'
            },
            { 
                'value': 'Especialista em Córnea',
                'label': 'Especialista em Córnea'
            },
            { 
                'value': 'Especialista em Lentes de Contato',
                'label': 'Especialista em Lentes de Contato'
            },
            { 
                'value': 'Neuroftalmologista',
                'label': 'Neuroftalmologista'
            }
        ]
    }

    const appoitmentTypesOptions = () => {
        return [
            { 
                'value': 'Consulta',
                'label': 'Consulta'
            },
            { 
                'value': 'Exame',
                'label': 'Exame'
            },
            { 
                'value': 'Retorno',
                'label': 'Retorno'
            },
            { 
                'value': 'Teleatendimento',
                'label': 'Teleatendimento'
            },
        ];
    }

    const getHealthPlanOptions = () => {
        api.getAllClinicHealthPlans(clinicId)
        .then(async res => {

            const options = res.data.reduce((acc, el) => {
                if (!el.health_plan?.is_sus_health_plan) {
                    acc.push({
                        label: el.health_plan.fantasy_name,
                        value: el.health_plan_id
                    });
                }
                return acc;
            }, []);

            setHealthPlanOptions(options)
            setHealthPlan(options.filter(el => el.label.toLowerCase() === 'particular')[0].value)
        })
        .catch(err => {
           console.log(err)      
        })
    }

    function getDay(day){
        switch (day){
            case 'DOMINGO':
                return 0;
            case 'SEGUNDA-FEIRA':
                return 1;                         
            case 'TERCA-FEIRA':
                return 2;                           
            case 'QUARTA-FEIRA':
                return 3;
            case 'QUINTA-FEIRA':
                return 4;
            case 'SEXTA-FEIRA':
                return 5;
            case 'SABADO':
                return 6;
            default:
                break;
        }
    }

    const daysName = [
        'dom',
        'seg',
        'ter',
        'qua',
        'qui',
        'sex',
        'sáb'               
    ]

    const getScheduleConfigDay = () => {            
        api.getClinicScheduleDaysConfig(clinicId)
        .then(async res => {
            const clinicDays = res.data
            clinicDays.forEach(day => {
                let index = getDay(day.day)

                scheduleDays[index] = {
                    'weekDay': daysName[index],
                    'active': day.status ? true : false
                };

                setScheduleDays(scheduleDays)
            });
        })
        .catch(err => {
           console.log(err)      
        })
    }

    const getOutages = () => {            
        api.getAllOutages(clinicId)
        .then(async res => {
            setAllOutages(res.data)
            const clinicOut = res.data.filter(outage => {
                return !outage.room_id && !outage.professional_id && (outage.repeat === 'Anual' || (moment().format('YYYY-mm-dd') >= moment(outage.start).format('YYYY-mm-dd') && moment(outage.start).format('YYYY-mm-dd') <= moment().add(1, 'y').format('YYYY-mm-dd')))
            })            
            const outageDates = []

            clinicOut.forEach(clinicOutage => {
                let outageDate = clinicOutage.repeat === 'Anual' ? 
                    moment().format('YYYY')+"-"+moment(clinicOutage.start).format('MM')+"-"+moment(clinicOutage.start).format('DD'):                
                    clinicOutage.start
                const endOutageDate = clinicOutage.repeat === 'Anual' ? 
                    moment().format('YYYY')+"-"+moment(clinicOutage.end).format('MM')+"-"+moment(clinicOutage.end).format('DD'):                
                    clinicOutage.end
                                   
                if(!outageDates.find(out => out === outageDate)) outageDates.push(outageDate)
                if(outageDate < endOutageDate){
                    while(outageDate <= endOutageDate){
                        outageDate = moment(outageDate).add(1, 'day').format('YYYY-MM-DD')
                        if(!outageDates.find(out => out === outageDate)) outageDates.push(outageDate)
                    };
                }
            });
            setClinicOutageDates(outageDates)
        })
        .catch(err => {
           console.log(err)      
        })
    }

    const getProfessionalOutages = () => {     
        const professionalOut = allOutages.filter(outage => {
            return !outage.room_id && outage.professional_id === selectedProfessional && (outage.repeat === 'Anual' || (moment().format('YYYY-mm-dd') >= moment(outage.start).format('YYYY-mm-dd') && moment(outage.start).format('YYYY-mm-dd') <= moment().add(1, 'y').format('YYYY-mm-dd')))
        })

        const outageDates = []

        professionalOut.forEach(professionalOutage => {
            let outageDate = professionalOutage.repeat === 'Anual' ? 
                moment().format('YYYY')+"-"+moment(professionalOutage.start).format('MM')+"-"+moment(professionalOutage.start).format('DD'):                
                professionalOutage.start
            const endOutageDate = professionalOutage.repeat === 'Anual' ? 
                moment().format('YYYY')+"-"+moment(professionalOutage.end).format('MM')+"-"+moment(professionalOutage.end).format('DD'):                
                professionalOutage.end
                                
            if(!outageDates.find(out => out === outageDate)) outageDates.push(outageDate)
            while(outageDate !== endOutageDate){
                outageDate = moment(outageDate).add(1, 'day').format('YYYY-MM-DD')
                if(!outageDates.find(out => out === outageDate)) outageDates.push(outageDate)
            };
        });

        setProfessionalOutageDates(outageDates)
    }

    const getCalendarProfessionals = () => {
        api.getFilteredPublicScheduleProfessional({
            publicScheduleId: publicScheduleId,
            selectedProfessional: selectedProfessional,
            subspecialty: subspecialty,
            appointmentType: appointmentType,
            healthPlan: healthPlan,
            day: moment(selectedDate).weekday()
        })
        .then(async res => {
            if (res.status === 200) {
                setCalendarProfessionals(res.data)
            }
        })
        .catch(err => {
            console.log(err)      
        })
    }

    function getDayName(day){
        switch (day){
            case 0:
                return 'DOMINGO';
            case 1:
                return 'SEGUNDA-FEIRA';                         
            case 2:
                return 'TERCA-FEIRA';                           
            case 3:
                return 'QUARTA-FEIRA';
            case 4:
                return 'QUINTA-FEIRA';
            case 5:
                return 'SEXTA-FEIRA';
            case 6:
                return 'SABADO';
            default:
                break;
        }
    }

    function sortInterval(a, b) {
        if (a.day_interval.start_time < b.day_interval.start_time) {
            return -1;
        }
        if (a.day_interval.start_time > b.day_interval.start_time) {
            return 1;
        }
        return 0;
    }

    const loadProfessional = async () => {
        const weekDay = parseInt(moment(selectedDate, 'YYYY-MM-DD').format('e'))
        setHasAppointments(false)
        setProfessionalsLoaded(false)
        setIsLoading(true)

        if (!appointmentType) {
            setIsLoading(false)
            return;
        }
        
        for await (const professional of calendarProfessionals) {
            // Create a key for the professional, used on the render
            professional.key = professional.person.id.replace(/[^a-zA-Z0-9]/g, '');

            const procedures = professional?.procedures?.filter(procedure => {
                return procedure.type === appointmentType
            })

            const todayProcedures = procedures.filter(procedure => {
                return procedure.pub_schedule_intervals.find(interval => {
                    return interval.day === moment(selectedDate).weekday()
                })
            })

            if (selectedProfessional) {
                const newScheduleDays = []

                for (let i = 0; i < 7; i++) {
                    newScheduleDays.push({
                        'weekDay': daysName[i],
                        'active': false
                    })
                }

                for await (const procedure of procedures) {
                    for await (const interval of procedure.pub_schedule_intervals) {
                        newScheduleDays[interval.day].active = true
                    }
                }

                setScheduleDays(newScheduleDays)
            }

            // Retrieve professional intervals for the day
            let professionalDayIntervals = professional?.person?.public_schedule_professional?.procedures[0]?.pub_schedule_intervals?.filter(day => {
                return day.day === weekDay
            })

            const intervals = []

            for await (const procedure of todayProcedures) {
                for await (const interval of procedure.pub_schedule_intervals) {
                    if (interval.day === weekDay && !intervals.find(savedInterval => {
                        return savedInterval.day_interval.id === interval.day_interval.id
                    }));
                    
                    intervals.push(interval)
                }
            }

            let appointments = await api.getProfessionalClinicAppointments(clinicId, {
                start: moment(selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                end: moment(selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                professionalIds: [professional.person_id]
            });

            if (appointments.data) {
                setHasAppointments(true);
            }

            if (intervals.length) {
                intervals.sort(sortInterval);
            }

            let rows = [];
            if (professionalDayIntervals[0]) {
                professionalDayIntervals.forEach(intervalConfig => {
                    let start_time = moment(intervalConfig.day_interval.start_time, 'HH:mm:ss');
                    let end_time = moment(intervalConfig.day_interval.end_time, 'HH:mm:ss');
                    for (let hour = start_time; hour < end_time;) {
                        const currentInterval = intervals.find(interval => {
                            return moment(hour, 'HH:mm:ss') >= moment(interval.day_interval.start_time, 'HH:mm:ss') &&
                                moment(hour, 'HH:mm:ss') <= moment(interval.day_interval.end_time, 'HH:mm:ss')
                        })
    
                        const duration = currentInterval?.day_interval
    
                        if (!currentInterval) {
                            break;
                        }
    
                        // In case that current iteration doesnt have a duration, jump to next
                        if (!duration) {
                            continue;
                        }
    
                        const nextHour = duration ? moment(hour).add(duration?.duration_time, 'minutes') : null
    
                        // In case that next hour is next day, break
                        if (nextHour && nextHour.format('DD') !== hour.format('DD')) break;
    
                        const IntervalsContinues = intervals.find(interval => {
                            return moment(interval.day_interval.start_time, 'HH:mm:ss').format('HH:mm:ss') <= nextHour.format('HH:mm:ss') &&
                                moment(interval.day_interval.end_time, 'HH:mm:ss').format('HH:mm:ss') >= nextHour.format('HH:mm:ss')
                        })
    
                        if (currentInterval && nextHour || IntervalsContinues) {
                            if (!(appointments.data && appointments.data.find(appointment => {
                                return moment(hour, 'HH:mm:ss') >= moment(moment(appointment.start_datetime).format('HH:mm:ss'), 'HH:mm:ss') &&
                                    moment(hour, 'HH:mm:ss') < moment(moment(appointment.end_datetime).format('HH:mm:ss'), 'HH:mm:ss') && appointment.status !== 'CANCELLED_BY_PATIENT'
                            }))) {
                                if (!(moment(selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(hour, 'HH:mm:ss').format('HH:mm:ss') < moment().format('HH:mm:ss'))) {
                                    rows.push({
                                        hour: moment(hour, 'HH:mm:ss').format('HH:mm'),
                                        duration: duration?.duration_time
                                    })
                                }
                            }
                            hour = nextHour
                        }
                        else {
                            const indexInterval = intervals.indexOf(currentInterval)
    
                            if (indexInterval < intervals.length) {
                                hour = moment(intervals[indexInterval + 1]?.day_interval.start_time, 'HH:mm:ss')
                            }
                            else {
                                hour = end_time
                            }
                        }
                        
                    }
                })
                professional.interval = rows;
            }
        }

        setProfessionalsLoaded(true)
        setIsLoading(false)
    }

    const isActivatedDate = (date) => {
        return !desactivadDays.includes(date.getDay()) &&
            healthPlan &&
            appointmentType &&
            !clinicOutageDates.includes(moment(date).format('YYYY-MM-DD')) && 
            !professionalOutageDates.includes(moment(date).format('YYYY-MM-DD'))
    };

    function changeDate(direction){
        if(!isLoading){     
            const newSelectDate = direction === 'r' ? 
                moment(selectedDate).add(1, 'd') :
                moment(selectedDate).subtract(1, 'd')
                
            if(!desactivadDays.includes(newSelectDate.day())) setIsLoading(true)

            if(direction === 'r'){            
                setSelectedDate(newSelectDate.format('YYYY-MM-DD'))
                if(moment(selectedDate).diff(moment(date), 'days') > (size  === 'sm' ?  0 : 1)) setDate(moment(date).add(1, 'd').format('YYYY-MM-DD'))
            }
            else{
                if(moment(selectedDate).subtract(1, 'd').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) setSelectedDate(newSelectDate.format('YYYY-MM-DD'))
                if(moment(date).subtract(1, 'd').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) setDate(moment(date).subtract(1, 'd').format('YYYY-MM-DD'))
            }
        }        
    }

    const disableDate = (dateToDisable) => {
        return (clinicOutageDates && clinicOutageDates.includes(dateToDisable)) ||
            (professionalOutageDates && professionalOutageDates.includes(dateToDisable));
    }
    
    return (
        <CalendarStyled>
            <Row>
                <Col xs="12" lg="3" className="mb-3">
                    <Select
                        label="Tipo de agendamento"
                        options={appoitmentTypesOptions()}
                        noOption
                        value={appointmentType}
                        setValue={setAppointmentType}
                    />
                </Col>

                {
                    !professionalId &&
                    <Col xs="12" lg="3" className="mb-3">
                        <Select
                            label="Nome do(a) médico(a)"
                            options={professionalsOptions()}
                            noOption
                            value={selectedProfessional}
                            setValue={setSelectedProfessional}
                        />
                    </Col>
                }
                
                {
                    !professionalId &&
                    <Col xs="12" lg="3" className="mb-3">
                        <Select
                            label="Subespecialidade"
                            subLabel="(opcional)"
                            options={subSpecialtyOptions()}
                            noOption
                            value={subspecialty}
                            setValue={setSubspecialty}
                        />
                    </Col>
                }

                <Col xs="12" lg="3" className="mb-3">
                    <Select
                        label="Convênio"
                        options={healthPlanOptions}
                        value={healthPlan}
                        setValue={setHealthPlan}
                    />
                </Col>
            </Row>

            <h4>Datas disponíveis</h4>

            <HeaderCardStyled>
                <SpanStyled>       
                    <img
                        src={CalendarSvg}
                        width="17px"
                        height="24px"
                        alt="phone svg"
                    />

                    <span ref={target} onClick={() => {openCalendar()}}>
                        Visualizar calendário       
                    </span>

                    <Overlay target={target.current} show={openDatePicker} placement="bottom">
                        <Popover>                
                            <DatePicker
                                filterDate={isActivatedDate}
                                startDate={new Date()}
                                selected={moment(selectedDate).toDate()}
                                inline
                                locale={ptBR}
                                dateFormat="dd/MM/yyyy"
                                onChange={(value) => {selectDate(value)}}
                                excludeDateIntervals={[{start: (new Date(`${new Date().getFullYear()}-01-01`)), end: new Date(Date.now() - 86400000)}]}
                                minDate={new Date(`01/01/${new Date().getFullYear()}`)}
                                maxDate={new Date(new Date().setMonth(new Date().getMonth() + 6))}
                            />        
                        </Popover>
                    </Overlay>

                </SpanStyled>
                
                <NavStyled variant="pills" activeKey={moment(selectedDate, 'YYYY-MM-DD')} fill navbarScroll={true} onSelect={handleSelect}>
                    <NavItemStyled>
                        {
                            moment(selectedDate).subtract(1, 'd').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') && healthPlan && appointmentType && 
                            <img
                                onClick={() => {changeDate('l')}}
                                src={ChevronLeft}
                                width="17px"
                                height="24px"
                                alt="svg"
                            />
                        }    
                    </NavItemStyled>

                    <Nav.Item>
                        <NavLinkStyled 
                            eventKey={moment(date, 'YYYY-MM-DD')} 
                            disabled={
                                disableDate(moment(date).format('YYYY-MM-DD')) ||
                                !(healthPlan && appointmentType) || scheduleDays && scheduleDays.find(day => {return day && !day.active && day.weekDay === moment(date, 'YYYY-MM-DD').locale('pt-br').format('ddd')})
                            }
                        >
                            {moment(date, 'YYYY-MM-DD').locale('pt-br').format('ddd').toUpperCase()} <br />
                            <InlineStyled>
                                <span>{moment(date, 'YYYY-MM-DD').locale('pt-br').format('MMM')} </span>
                                {moment(date, 'YYYY-MM-DD').locale('pt-br').format('DD')}
                            </InlineStyled>          
                        </NavLinkStyled>
                    </Nav.Item>

                    <Nav.Item>
                        <NavLinkStyled 
                            eventKey={moment(date, 'YYYY-MM-DD').add(1, 'days')}
                            disabled={
                                disableDate(moment(date).add(1, 'days').format('YYYY-MM-DD')) ||
                                !(healthPlan && appointmentType) || scheduleDays && scheduleDays.find(day => {return day && !day.active && day.weekDay === moment(date, 'YYYY-MM-DD').add(1, 'days').locale('pt-br').format('ddd')})
                            }
                        >
                            {moment(date, 'YYYY-MM-DD').add(1, 'days').locale('pt-br').format('ddd').toUpperCase()} <br />
                            <InlineStyled>
                                <span>{moment(date, 'YYYY-MM-DD').add(1, 'days').locale('pt-br').format('MMM')} </span>
                                {moment(date, 'YYYY-MM-DD').add(1, 'days').locale('pt-br').format('DD')}
                            </InlineStyled>                        
                        </NavLinkStyled>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLinkStyled 
                            eventKey={moment(date, 'YYYY-MM-DD').add(2, 'days')}
                            disabled={
                                disableDate(moment(date).add(2, 'days').format('YYYY-MM-DD')) ||
                                !(healthPlan && appointmentType) || scheduleDays && scheduleDays.find(day => {return day && !day.active && day.weekDay === moment(date, 'YYYY-MM-DD').add(2, 'days').locale('pt-br').format('ddd')})
                            }
                        >
                            {moment(date, 'YYYY-MM-DD').add(2, 'days').locale('pt-br').format('ddd').toUpperCase()} <br />
                            <InlineStyled>
                                <span>{moment(date, 'YYYY-MM-DD').add(2, 'days').locale('pt-br').format('MMM')} </span>
                                {moment(date, 'YYYY-MM-DD').add(2, 'days').locale('pt-br').format('DD')}
                            </InlineStyled>
                        </NavLinkStyled>
                    </Nav.Item>
                    {
                        size  !== 'sm' &&
                        <>                            
                            <Nav.Item>
                                <NavLinkStyled 
                                    eventKey={moment(date, 'YYYY-MM-DD').add(3, 'days')}
                                    disabled={
                                        disableDate(moment(date).add(3, 'days').format('YYYY-MM-DD')) ||
                                        !(healthPlan && appointmentType) || scheduleDays && scheduleDays.find(day => {return day && !day.active && day.weekDay === moment(date, 'YYYY-MM-DD').add(3, 'days').locale('pt-br').format('ddd')})
                                    }
                                >
                                    {moment(date, 'YYYY-MM-DD').add(3, 'days').locale('pt-br').format('ddd').toUpperCase()} <br />
                                    <InlineStyled>
                                        <span>{moment(date, 'YYYY-MM-DD').add(3, 'days').locale('pt-br').format('MMM')} </span>
                                        {moment(date, 'YYYY-MM-DD').add(3, 'days').locale('pt-br').format('DD')}
                                    </InlineStyled>
                                </NavLinkStyled>
                            </Nav.Item>

                            <Nav.Item>
                                <NavLinkStyled
                                    eventKey={moment(date, 'YYYY-MM-DD').add(4, 'days')}
                                    disabled={
                                        disableDate(moment(date).add(4, 'days').format('YYYY-MM-DD')) ||
                                        !(healthPlan && appointmentType) || scheduleDays && scheduleDays.find(day => {return day && !day.active && day.weekDay === moment(date, 'YYYY-MM-DD').add(4, 'days').locale('pt-br').format('ddd')})
                                    }
                                >
                                    {moment(date, 'YYYY-MM-DD').add(4, 'days').locale('pt-br').format('ddd').toUpperCase()} <br />
                                    <InlineStyled>
                                        <span>{moment(date, 'YYYY-MM-DD').add(4, 'days').locale('pt-br').format('MMM')} </span>
                                        {moment(date, 'YYYY-MM-DD').add(4, 'days').locale('pt-br').format('DD')}
                                    </InlineStyled>
                                </NavLinkStyled>
                            </Nav.Item>
                        </>
                    }

                    {
                        healthPlan && appointmentType && 
                        <NavItemStyled>                        
                            <img
                                onClick={() => {changeDate('r')}}
                                src={ChevronRight}
                                width="17px"
                                height="24px"
                                alt="phone svg"
                            />
                        </NavItemStyled>
                    }
                </NavStyled>
            </HeaderCardStyled>
            
            {
                calendarProfessionals && calendarProfessionals.length && appointmentType && professionalsLoaded ?                    
                    calendarProfessionals.map((professional) => {
                        return  <ProfessionalCalendarCard
                                key={professionalId}
                                professionalId={professionalId}
                                professional={professional}
                                day={moment(selectedDate, 'YYYY-MM-DD')}
                                healthPlanId={healthPlan}
                                clinicId={clinicId}
                                loadAppointments={loadProfessional}
                                getCalendarProfessionals={getCalendarProfessionals}
                                appointmentType={appointmentType}
                                disableDate={disableDate(moment(selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}
                            />   
                    
                    })
                :   
                    <TextCardStyled>
                        Configure os filtros acima para selecionar e agendar um hórario
                    </TextCardStyled>
            }
        </CalendarStyled>
    )
}

export default Calendar;