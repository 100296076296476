import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row} from 'react-bootstrap';
import { ModalTitleStyled } from './styles'

import moment from "moment";
import Select from "../../Forms/select";
import Input from "../../Forms/input";
import InputMasked from "../../Forms/input-masked";

const CreateAppointmentModal = ({setPersonData, show, setShow, procedures, setProcedureId, procedureId, dayNumber, selectedHour, setCanSchedule, canSchedule}) => {
    const [errors, setErrors] = useState();
    const [procedureOptions, setProcedureOptions] = useState()
    const [cpf, setCPF] = useState()
    const [name, setName] = useState()
    const [email, setEmail] = useState() 
    const [cellphone, setCellphone] = useState()
    const [birthday, setBirthday] = useState()

    useEffect(() => {
        const proceduresOpts = []
        procedures?.forEach(procedure => {
            procedure.pub_schedule_intervals.forEach(interval => {
                if(interval.day === dayNumber && (moment(interval.day_interval.start_time, 'HH:mm:ss') <= selectedHour && moment(interval.day_interval.end_time, 'HH:mm:ss') > selectedHour)){
                    proceduresOpts.push({
                        label: procedure?.item.name,
                        value: procedure?.item.id,
                        type: procedure?.item.type
                    })
                }
            })
        });

        setProcedureOptions(proceduresOpts)
    }, [procedures]);
    
    const handleShow = () => {
        if(procedures) setProcedureId(procedures[0]?.item.id)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setCPF()
        setEmail()
        setName()
        setCellphone()
        setBirthday()
    }

    function handleSubmit(event){
        event.preventDefault();
        setCanSchedule(false);

        const explodeBirthday = birthday ? birthday.split("/") : [];

        const validBirthday = () => {
            const regex = /^\d{2}\/\d{2}\/\d{4}$/;

            var formattedDate = new Date(parseInt(explodeBirthday[2]), parseInt(explodeBirthday[1]) - 1, parseInt(explodeBirthday[0]));

            if (!birthday) {
                return "O campo data de nascimento é obrigatório";
            }

            if (!regex.test(birthday)) {
              return "Data inválida";
            }
            
            if(
                (parseInt(explodeBirthday[0]) < 1 || parseInt(explodeBirthday[0]) > 31) ||
                (parseInt(explodeBirthday[1]) < 1 || parseInt(explodeBirthday[1]) > 12) ||
                (parseInt(explodeBirthday[2]) < 1920 || parseInt(explodeBirthday[2]) > new Date().getFullYear()) ||
                formattedDate > new Date()
            ){
                return "Data inválida";
            }
            
            if (formattedDate.getFullYear() !== parseInt(explodeBirthday[2]) || formattedDate.getMonth() !== (parseInt(explodeBirthday[1]) - 1) && formattedDate.getDate() !== parseInt(explodeBirthday[0])) {
                return "Data inválida";
            }
            
            return '';
        };

        if(!procedureId || !cpf || !name || !email || !cellphone || validBirthday().length > 0){

            setCanSchedule(true);

            setErrors({
                'procedureId': !procedureId ? 'O campo procedimento é obrigatório' : '',
                'cpf': !cpf ? 'O campo CPF é obrigatório' : '',
                'email': !email ? 'O campo email é obrigatório' : '',
                'name': !name ? 'O campo nome é obrigatório' : '',
                'cellphone': !cellphone ? 'O campo telefone é obrigatório' : '',
                'birthday': validBirthday()
            })
            
            return;
        }

        setPersonData({
            cpf: cpf,
            name: name,
            email: email,
            cellphone: cellphone,
            birthday:`${explodeBirthday[2]}-${explodeBirthday[1]}-${explodeBirthday[0]}`,
        })
    }

    return (
        <>
            <Button onClick={handleShow}>
                Agendar horário
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                centered
            >
                <Modal.Header closeButton>
                    <ModalTitleStyled >
                        Finalizar agendamento
                    </ModalTitleStyled>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col 
                                xs="12" 
                                className="mb-3"
                            >
                                <Select
                                    label="Procedimento"
                                    options={procedureOptions}
                                    value={procedureId}
                                    setValue={procedureId =>setProcedureId(procedureId)}
                                    error={errors ? errors.procedureId : ''}
                                    activeError
                                />
                            </Col>

                            <Col 
                                xs="12" 
                                className="mb-3"
                            >
                                <Input
                                    label="Nome completo" 
                                    type="text"
                                    placeholder="Digite seu nome completo"
                                    fieldValue={name} 
                                    setValue={name => setName(name)}
                                    error={errors ? errors.name : ''}
                                    activeError
                                />
                            </Col>

                            <Col 
                                xs="12" 
                                className="mb-3"
                            >
                                <InputMasked 
                                    label="CPF" 
                                    mask="999.999.999-99" 
                                    placeholder="Digite seu CPF"
                                    fieldValue={cpf} 
                                    setValue={cpf => setCPF(cpf)}
                                    error={errors ? errors.cpf : ''}
                                    activeError
                                />
                            </Col>

                            <Col 
                                xs="12" 
                                className="mb-3"
                            >
                                <InputMasked 
                                    label="Telefone" 
                                    mask="(99) 99999-9999" 
                                    placeholder="Digite seu telefone"
                                    fieldValue={cellphone} 
                                    setValue={cellphone => setCellphone(cellphone)}
                                    error={errors ? errors.cellphone : ''}
                                    activeError
                                />
                            </Col>

                            <Col 
                                xs="12" 
                                className="mb-3"
                            >
                                <Input
                                    label="E-mail" 
                                    type="email"
                                    placeholder="Digite seu email"
                                    fieldValue={email} 
                                    setValue={email => setEmail(email)}
                                    error={errors ? errors.email : ''}
                                    activeError
                                />
                            </Col>

                            <Col 
                                xs="12" 
                                className="mb-3"
                            >
                                <InputMasked 
                                    label="Data de nascimento" 
                                    mask="99/99/9999" 
                                    placeholder="Digite sua data de nascimento"
                                    fieldValue={birthday} 
                                    setValue={birthday => setBirthday(birthday)}
                                    error={errors ? errors.birthday : ''}
                                    activeError                             
                                />
                            </Col>
                            <Col 
                                xs="12" 
                                className="mb-3"
                            >
                                <p className="mt-2">Ao clicar em "Agendar", você declara ter lido e concordado com os nossos <a href="https://eyecarehealth.com.br/termos-e-politicas/termos-de-uso" target="_blank"> termos de uso</a> e <a href="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade" target="_blank"> aviso de privacidade.</a></p>
                            </Col>
                        </Row>

                        <Row className="px-2">                           
                            <Button 
                                type="submit"
                                disabled={!canSchedule}
                            >
                                Agendar
                            </Button>    
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateAppointmentModal;